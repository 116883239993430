/**
 * Api service class for making HTTP requests using axios.
 * 
 * @class Api
 * @param {string} [contentType="application/json"] - The content type for the request headers.
 * @param {string} [baseURL=BASE_URL] - The base URL for the axios instance.
 * 
 * @property {string} baseURL - The base URL for the axios instance.
 * @property {object} axiosObject - The axios instance.
 * 
 * @method get - Makes a GET request to the specified URL.
 * @param {string} url - The URL to make the GET request to.
 * @returns {Promise<any>} - The response data from the GET request.
 * 
 * @method post - Makes a POST request to the specified URL.
 * @param {string} url - The URL to make the POST request to.
 * @param {object} arg - The data to send in the POST request.
 * @returns {Promise<any>} - The response data from the POST request.
 * 
 * @method patch - Makes a PATCH request to the specified URL.
 * @param {string} url - The URL to make the PATCH request to.
 * @param {object} arg - The data to send in the PATCH request.
 * @returns {Promise<any>} - The response data from the PATCH request.
 * 
 * @method delete - Makes a DELETE request to the specified URL.
 * @param {string} url - The URL to make the DELETE request to.
 * @returns {Promise<any>} - The response data from the DELETE request.
 */
import axios from "axios";
import getToken from "../helpers/getToken";
import { BASE_URL } from "../constants/env";

export default class Api {
  constructor(contentType = "application/json", baseURL = BASE_URL) {
    this.baseURL = baseURL;
    this.axiosObject = axios.create({
      baseURL: this.baseURL,
      headers: {
        "Content-Type": contentType,
      },
    });

    this.axiosObject.interceptors.request.use(
      (config) => {
        const token = getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  get = async (url) => {
    const res = await this.axiosObject.get(url);
    return res.data;
  };

  post = async (url, { arg }) => {
    const res = await this.axiosObject.post(url, arg);
    return res.data;
  };

  patch = async (url, { arg }) => {
    const res = await this.axiosObject.patch(url, arg);
    return res.data;
  };

  delete = async (url) => {
    const res = await this.axiosObject.delete(url);
    return res.data;
  };
}
