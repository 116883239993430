import "./style.css";
import { Ellipsis, Ring as RingSpinner } from "react-css-spinners";

function Spinner({ isLarge = false }) {
  return <Ellipsis color="var(--orange-light)" size={isLarge ? 75 : 45} />;
}

/**
 * Ring component that renders a RingSpinner with customizable size, thickness, and color.
 *
 * @param {Object} props - The properties object.
 * @param {number} [props.size=25] - The size of the spinner.
 * @param {number} [props.thickness=3] - The thickness of the spinner.
 * @param {string} [props.color="var(--orange-light)"] - The color of the spinner.
 * @returns {JSX.Element} The RingSpinner component.
 */
export const Ring = ({
  size = 25,
  thickness = 3,
  color = "var(--orange-light)",
}) => <RingSpinner color={color} size={size} thickness={thickness} />;

export default Spinner;
