import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./layout";
import GradientBackground from "./components/GradientBackground";
import Loader from "./components/Loader";
import ROUTES from "./data/routes";
import useLanguagePreference from "./hooks/useLanguagePreference";
import Auth from "./components/Auth";
import AUTH_WRAPPED_PATHS from "./constants/authWrappedPaths.json";
import { ToastContainer } from "react-toastify";

function useStorageListener() {
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "persist:root") {
        window.location.reload();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
}

/**
 * Main application component.
 * 
 * This component sets up the routing for the application using React Router.
 * It also includes language preference and storage listener hooks, and displays
 * a toast notification container.
 * 
 * @component
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * // Usage
 * <App />
 * 
 * @version 1.10
 */
function App() {
  console.log("v.1.10");
  useLanguagePreference();
  useStorageListener();

  return (
    <Router basename="/">
      <Routes>
        {ROUTES.map(({ path, component }) => (
          <Route
            key={path}
            exact
            path={path}
            element={
              <Suspense fallback={<Loader />}>
                <Layout>
                  {path !== "/" && <GradientBackground />}
                  {AUTH_WRAPPED_PATHS.includes(path) ? (
                    <Auth>{React.createElement(component)}</Auth>
                  ) : (
                    React.createElement(component)
                  )}
                </Layout>
              </Suspense>
            }
          />
        ))}
      </Routes>
      <ToastContainer
        className="toastify-bottom-left"
        position="bottom-left"
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        autoClose={5000}
        stacked
      />
    </Router>
  );
}

export default App;
